import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import SydLabel, { hookFormErrorAdapter } from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import SydSelect from '../../../../../commonDesign/SydSelect'

const useStyles = makeStyles((theme) => ({
  formLayout: {
    maxWidth: '800px'
  },
  wrap: {
  },
  experience: {
    width: '100%'
  }
}))

const ContactInfoForm = forwardRef(function ContactInfoForm ({ user, teamMember, roles, editing }, ref) {
  const classes = useStyles({ editing })
  const options = useMemo(() => {
    return roles.map(x => ({
      label: x.longName,
      value: x.roleTypeId
    }))
  }, [roles])

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: user.userId,
      teamMember: teamMember || {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        title: '',
        email: user.email,
        phone1: '',
        phone2: '',
        phone3: '',
        roleTypeId: null
      }
    }
  })
  const { handleSubmit, reset } = form
  useImperativeHandle(ref, () => {
    return {
      handleSubmit,
      reset
    }
  }, [handleSubmit, reset])

  return (
    <div className={classes.wrap}>
      <Grid className={classes.formLayout} container spacing={1}>
        <Grid item xs={12}>
          <div>
            <Controller
              control={form.control}
              name='teamMember.roleTypeId'
              rules={{ required: { value: true, message: 'Must pick a role' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Role'
                  description='&nbsp;'
                  required
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydSelect size='sm' placeholder='Role' disabled={!editing} options={options} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Controller
              control={form.control}
              rules={{ maxLength: { value: 100, message: 'Too many characters' } }}
              name='teamMember.email'
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Email'
                  description={editing ? 'Max 100 characters' : <>&nbsp;</>}
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Email' disabled={!editing} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Controller
              control={form.control}
              name='teamMember.phone1'
              rules={{ maxLength: { value: 100, message: 'Too many characters' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Phone 1'
                  description={editing ? 'Max 100 characters' : <>&nbsp;</>}
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Phone 1' disabled={!editing} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Controller
              control={form.control}
              name='teamMember.phone2'
              rules={{ maxLength: { value: 100, message: 'Too many characters' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Phone 2'
                  description={editing ? 'Max 100 characters' : <>&nbsp;</>}
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Phone 2' disabled={!editing} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Controller
              control={form.control}
              name='teamMember.phone3'
              rules={{ maxLength: { value: 100, message: 'Too many characters' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Phone 3'
                  description={editing ? 'Max 100 characters' : <>&nbsp;</>}
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Phone 3' disabled={!editing} {...field} />
                </SydLabel>
              )}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
})

ContactInfoForm.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  }),
  teamMember: PropTypes.shape({
    title: PropTypes.string,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    phone3: PropTypes.string,
    roleTypeId: PropTypes.string
  }),
  roles: PropTypes.array,
  editing: PropTypes.bool
}

ContactInfoForm.defaultProps = {
  editing: false
}

export default ContactInfoForm
