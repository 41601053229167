import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useAdvisorUserDetails, useSectionEditing } from '../../AdvisorUserFormContext'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import EditButton from '../../../shared/EditButton'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import Loading from '../../../../../molecules/Loading'
import { useAdvisorTeamMember, useModifyAdvisorTeamMember, useRoleTypes } from '../../../../../../api/groups'
import ContactInfoForm from './ContactInfoForm'

const useStyles = makeStyles((theme) => ({
  experienceSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function AdvisorUserTeamMemberSection () {
  const { user, editSection, refetchUser, editing, cancelEdit } = useAdvisorUserDetails()
  const sectionEditing = useSectionEditing('contact_info')
  const { data: _teamMember, isLoading: teamMemberLoading } = useAdvisorTeamMember(user.userId)
  const { data: roles, isLoading: rolesLoading } = useRoleTypes()

  const classes = useStyles()
  const formRef = useRef()
  const { mutateAsync: modifyAdvisorTeamMember } = useModifyAdvisorTeamMember()
  const [processing, setProcessing] = useState(false)
  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        setProcessing(true)
        await modifyAdvisorTeamMember({
          userId: form.userId,
          teamMember: {
            firstName: form.teamMember.firstName || null,
            lastName: form.teamMember.lastName || null,
            title: form.teamMember.title || null,
            email: form.teamMember.email || null,
            phone1: form.teamMember.phone1 || null,
            phone2: form.teamMember.phone2 || null,
            phone3: form.teamMember.phone3 || null,
            roleTypeId: form.teamMember.roleTypeId
          }
        })
        await refetchUser()
        cancelEdit()
      } catch (err) {
        console.error('error trying to update contact information', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      } finally {
        setProcessing(false)
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, modifyAdvisorTeamMember, refetchUser, setProcessing, cancelEdit])

  if (teamMemberLoading || rolesLoading) {
    return (
      <Loading />
    )
  }

  return (
    <SectionScreen
      editing={editing}
      sectionIsEditing={sectionEditing}
      className={classes.experienceSection}
    >
      <SectionHeader text='Client Facing Contact Information' subText='This contact information will be visible to your wealth owners'>
        <div>
          <EditButton
            policy='admin_edit_advisor_users'
            editing={sectionEditing}
            onClick={() => editSection({
              section: 'contact_info'
            })}
          />
        </div>
      </SectionHeader>
      <ContactInfoForm
        ref={formRef}
        user={user}
        teamMember={_teamMember.teamMember}
        roles={roles}
        editing={sectionEditing}
      />
      {sectionEditing ? (
        <SaveCancelFooter
          saveText='Save Contact Information'
          onCancel={() => {
            formRef.current.reset()
            cancelEdit()
          }}
          onSave={submitter}
          processing={processing}
        />
      ) : null}
    </SectionScreen>
  )
}

export default AdvisorUserTeamMemberSection
