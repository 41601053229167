import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: theme.typography.fontSizes.h4
  },
  subText: {
    fontStyle: 'italic',
    fontWeight: theme.typography.weights.light,
    fontSize: theme.typography.fontSizes.md
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    margin: ({ slim }) => slim ? '0' : '15px 0'
  }
}))

function SectionHeader ({ children, text, subText, slim }) {
  const classes = useStyles({ slim })

  return (
    <div className={classes.sectionHeader}>
      <div>
        <div className={classes.headerText}>{text}</div>
        {subText ? (<div className={classes.subText}>{subText}</div>) : null}
      </div>
      <>
        {children}
      </>
    </div>
  )
}

SectionHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  subText: PropTypes.string,
  slim: PropTypes.bool
}

export default SectionHeader
