import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postNamedQuery } from '../../../../../../../service'
import { QUERY_KEYS } from '../../../../../../../api/queryKeys'
import { normalizeBillingRun } from './normalizeBillingRun'

export const useUpdateBillingRunRecordId = ({ query, columnsConfig, billingRun }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ billingRunRecordId, data }) => {
      const { data: billingRunData } = await postNamedQuery('billing', 'get-billing-run-details', {
        ...query,
        filters: {
          ...(query.filters ?? {}),
          billingRunRecordId: [{ op: 'eq', value: billingRunRecordId, combine: 'AND' }]
        }
      })
      const normalizedData = normalizeBillingRun(columnsConfig.columns, billingRunData, billingRun.warnings)
      return data.map((item) => {
        const matchingData = normalizedData?.find((newData) => newData.billingRunRecordId === item.billingRunRecordId)
        return matchingData ?? item
      })
    },
    onSuccess: (data) => {
      console.log(query)
      queryClient.setQueryData([QUERY_KEYS.billingRunDetails, query], data)
    }
  })
}
