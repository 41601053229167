import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { useButtonStyles } from './useButtonStyles'

const ICON_SIZES = {
  xs: 10,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24
}

function SydButton ({
  children,
  disabled,
  icon,
  onClick,
  priority,
  style,
  variant,
  size,
  processing,
  type = 'button'
}) {
  const classes = useButtonStyles({ priority, variant, size })
  const iconComponent = useMemo(() => {
    if (processing) {
      return <CircularProgress className='__progress' size={ICON_SIZES[size]} />
    }
    return icon ? (<Icon name={icon} customSize={ICON_SIZES[size]} />) : undefined
  }, [icon, processing, size])

  return (
    <Button
      style={style}
      startIcon={iconComponent}
      className={classes.commonDesignButton}
      disabled={disabled || processing}
      onClick={onClick}
      type={type}
    >
      {children}
    </Button>
  )
}

SydButton.propTypes = {
  priority: PropTypes.oneOf(['main', 'warning']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline', 'ghost']),
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  processing: PropTypes.bool,
  type: PropTypes.string
}

SydButton.defaultProps = {
  priority: 'main',
  variant: 'outline',
  size: 'md',
  icon: null,
  policy: null,
  disabled: false,
  processing: false
}

export default SydButton
