import React from 'react'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { usePolicy } from '../../../../../hooks/usePolicy'
import PersonalSpace from '../../shared/PersonalSpace'
import AdvisorUserInfoSection from './AdvisorUserInfoSection'
import AdvisorUserActionsSection from './AdvisorUserActionsSection'
import AdvisorUserPermissionsSection from './AdvisorUserPermissionsSection'
import AdvisorUserTeamMemberSection from './AdvisorUserTeamMemberSection'

function GeneralTab () {
  const canEdit = usePolicy('admin_edit_advisor_users')

  return (
    <FadeIn>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AdvisorUserInfoSection />
          <AdvisorUserTeamMemberSection />
        </Grid>
        {canEdit ? (
          <Grid item xs={12} md={6}>
            <AdvisorUserPermissionsSection />
            <AdvisorUserActionsSection />
          </Grid>
        ) : null}
      </Grid>
      <PersonalSpace />
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
