import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  fetchAssets,
  fetchClientBalances,
  fetchCustomDataset,
  fetchLevelDates,
  getAssetTearSheet,
  getHolidays,
  postNamedQuery,
  getAssetClassificationTagTypes,
  fetchCoreSeriesData,
  fetchAllocationHistory, postNamedCommand
} from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { mapDatesIntoTree } from '../utils'
import { QUERY_KEYS } from './queryKeys'

export const useAssetClassificationTagTypes = () => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.assetClassificationTagTypes, userId],
    queryFn: async () => {
      const { data } = await getAssetClassificationTagTypes()
      return data
    }
  })
}

export const useAssets = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['assets', userId, query],
    queryFn: async () => {
      const { data } = await fetchAssets(query)

      return data
    }
  })
}

export const useClientBalances = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['clientBalances', userId, query],
    queryFn: async () => {
      const { data } = await fetchClientBalances(query)

      return data
    }
  })
}

export const useLevelDates = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.levelDates, userId, query],
    queryFn: async () => {
      const { data } = await fetchLevelDates(query)

      return data
    }
  })
}

export const useClientDates = (clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.levelDates, 'clientDates', userId, clientId],
    queryFn: async () => {
      const { data } = await fetchLevelDates({
        levelType: 'client',
        clientIds: [clientId]
      })
      return data
    },
    enabled: !!clientId
  })
}

export const useCustodians = (queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.custodians, userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'custodians', {})
      return data
    },
    select: mapper,
    enabled
  })
}

export const useHolidays = (options = {}) => {
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: ['holidays'],
    queryFn: async () => {
      const { data } = await getHolidays({})

      return mapDatesIntoTree(data)
    }
  })
}

export const useCoreDataDataset = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.coreDataDataset, userId, query],
    queryFn: async () => {
      const { data } = await fetchCustomDataset('coreData', query)
      return data
    }
  })
}

export const useClientNetWorth = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.clientNetWorth, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-client-net-worth', query)
      return data
    }
  })
}

export const useAccountSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const enabled = options?.enabled ?? true
  return useQuery({
    queryKey: [QUERY_KEYS.accountSearch, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'searchAccounts', query)
      return data
    },
    enabled
  })
}

export const useAccountSearchMultiple = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.accountSearchMultiple, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'searchAccountsMultiple', query)
      return data
    }
  })
}

export const useAccountStatusDetailsSearch = (query, multiple = false) => {
  const { userId } = useAppContext()
  const queryName = useMemo(() => multiple ? 'searchAccountsMultiple' : 'searchAccounts', [multiple])
  return useQuery({
    queryKey: [QUERY_KEYS.accountStatusDetailsSearch, userId, query, queryName],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', queryName, query)
      return data
    },
    keepPreviousData: true
  })
}

export const useTransactionsSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchTransactions, userId, query],
    queryFn: async () => {
      if (!query) return null
      const { data } = await postNamedQuery('coreData', 'transactions', query)
      return data
    }
  })
}

export const useTransactionCodeTagSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchTransactionCodeTags, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'transactionCodeTags', query)
      return data
    },
    select: mapper
  })
}

export const useAssetSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchAssets, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'assets', query)
      return data
    },
    select: mapper
  })
}

export const useAssetTypes = (options = {}) => {
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.assetTypes],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-asset-types')
      return data
    },
    select: mapper
  })
}

export const useAssetExposureSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchAssetExposure, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'assetExposure', query)
      return data
    }
  })
}

export const useRunBookOfBusinessReport = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: ['runBobbi', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'runAssetExposure', query)
      return data
    }
  })
}

export const useQueryBookOfBusinessReport = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    enabled,
    queryKey: ['queryBobbi', userId, query],
    refetchInterval: (data) => {
      if (!data) return options?.interval || 5000

      return undefined
    },
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'queryAssetExposure', query)
      return data
    }
  })
}

export const useGainLossSearch = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  const result = useQuery({
    enabled,
    queryKey: [QUERY_KEYS.searchGainLoss, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'gainLoss', query)
      return data
    }
  })

  const queryClient = useQueryClient()
  const fetchMore = useCallback(async query => {
    return await queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.searchGainLoss, userId, query],
      queryFn: async () => {
        const { data } = await postNamedQuery('coreData', 'gainLoss', query)
        return data
      }
    })
  }, [queryClient, userId])
  return {
    ...result,
    fetchMore
  }
}

export const useComponentsOfChangeQuery = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, keepPreviousData, mapper } = options
  return useQuery({
    enabled,
    keepPreviousData,
    queryKey: [QUERY_KEYS.componentsOfChangeQuery, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'componentsOfChange', query)
      return data
    },
    select: mapper
  })
}

export const useClassificationTags = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.classificationTags, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'classificationTags',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useClassificationTagTypes = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.classificationTagTypes, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'classificationTagTypes',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useAssetsHoldings = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.assetHoldings, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'positions',
        query
      )
      return data
    }
  })
}

function getCoreTableData (query) {
  return async () => {
    const { data } = await postNamedQuery('coreData', 'getCoreTableData', query)

    return data
  }
}

function getCoreTable (query) {
  return async () => {
    const { data } = await postNamedQuery('coreData', 'getGroupedCoreData', query)

    return data
  }
}

export const useGetGroupedCoreData = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, keepPreviousData, mapper, queryKey } = options
  return useQuery({
    enabled,
    keepPreviousData,
    queryKey: [queryKey || QUERY_KEYS.getGroupedCoreData, userId, query],
    queryFn: getCoreTable(query),
    select: mapper
  })
}

export const useCoreTableData = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, keepPreviousData } = options
  return useQuery({
    enabled,
    keepPreviousData,
    queryKey: [QUERY_KEYS.getCoreTableDataQuery, userId, query],
    queryFn: getCoreTableData(query)
  })
}

export const useCoreTableDataMultiple = (queries, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  const queryMap = useMemo(() => {
    return queries.map((query) => ({
      enabled,
      queryKey: [QUERY_KEYS.getCoreTableDataQuery, userId, query],
      queryFn: getCoreTableData(query)
    }))
  }, [queries, userId, enabled])

  const results = useQueries({
    queries: queryMap
  })

  /** React Query returns a new array every render, this helps determine us memoize the result array */
  const maxResultVersion = useMemo(() => {
    return results.reduce((prev, cur) => Math.max(prev, cur.dataUpdatedAt), 0)
  }, [results])

  const isLoading = useMemo(() => {
    return results.reduce((prev, cur) => prev || cur.isLoading || cur.isFetching, false)
  }, [results])

  const [safeResult, setSafeResult] = useState(results)
  useEffect(() => {
    setSafeResult(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeResult, maxResultVersion])

  const queryClient = useQueryClient()
  const fetchMore = useCallback(async query => {
    return await queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.getCoreTableDataQuery, userId, query],
      queryFn: getCoreTableData(query)
    })
  }, [queryClient, userId])

  return {
    results: safeResult,
    fetchMore,
    isLoading
  }
}

export const useNormalizeRelativeDates = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.normalizeRelativeDates, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'normalizeRelativeDates',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useNormalizeDates = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: [QUERY_KEYS.normalizeDates, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'normalizeDates',
        query
      )
      return data
    },
    select: mapper
  })
}

export const useAlternativesMultiple = (queries, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  const queryMap = useMemo(() => {
    return queries.map((query) => ({
      enabled,
      queryKey: [QUERY_KEYS.alternatives, userId, query],
      queryFn: async () => {
        const { data } = await postNamedQuery('coreData', 'alternatives', query)
        return data
      },
      select: mapper
    }))
  }, [enabled, queries, mapper, userId])

  const results = useQueries({
    queries: queryMap
  })

  /** React Query returns a new array every render, this helps determine us memoize the result array */
  const maxResultVersion = useMemo(() => {
    return results.reduce((prev, cur) => Math.max(prev, cur.dataUpdatedAt), 0)
  }, [results])

  const [safeResult, setSafeResult] = useState(results)
  useEffect(() => {
    setSafeResult(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeResult, maxResultVersion])

  const queryClient = useQueryClient()
  const fetchMore = useCallback(async query => {
    return await queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.alternatives, userId, query],
      queryFn: async () => {
        const { data } = await postNamedQuery('coreData', 'alternatives', query)
        return data
      }
    })
  }, [queryClient, userId])

  return {
    results: safeResult,
    fetchMore
  }
}

export const useAlternatives = (query) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.alternatives, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'alternatives', query)
      return data
    }
  })
}

export const useAssetTearSheet = (assetId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEYS.assetTearSheet, userId, assetId],
    queryFn: async () => {
      const { data } = await getAssetTearSheet(assetId)
      return data
    }
  })
}

export const useProjectedIncome = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.projectedIncome, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'projectedIncome',
        query
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useCoreSeriesData = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.coreSeriesData, userId, query],
    queryFn: async () => {
      const { data } = await fetchCoreSeriesData(query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useBenchmarkReturns = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.getBenchmarkReturns, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery(
        'coreData',
        'getBenchmarkReturns',
        query
      )
      return data
    },
    select: mapper,
    enabled
  })
}

export const useAllocationHistory = (query, queryOptions = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = queryOptions
  return useQuery({
    queryKey: [QUERY_KEYS.allocationHistory, userId, query],
    queryFn: async () => {
      const { data } = await fetchAllocationHistory(query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useFirmReportMetrics = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, keepPreviousData = false } = options
  return useQuery({
    queryKey: ['firm-report-metrics', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-firm-wide-report-metrics', query)

      return data
    },
    enabled,
    keepPreviousData
  })
}

export const useFirmReportConfiguration = (reportId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: ['firm-report-configuration', userId, reportId],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-firm-wide-report-configuration', {
        reportId
      })

      return data
    },
    enabled
  })
}

export const useFirmReportSummary = (reportId, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, skip, take, membershipFilter } = options
  const membershipFilterKey = membershipFilter ? JSON.stringify(membershipFilter) : 'null'
  return useQuery({
    queryKey: ['firm-report-summary', userId, reportId, skip, take, membershipFilterKey],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-firm-wide-report-summary', {
        reportId,
        membershipFilter,
        skip,
        take
      })

      return data
    },
    enabled
  })
}

export const usePrefetchFirmReportMetrics = () => {
  const { userId } = useAppContext()
  const queryClient = useQueryClient()
  return useCallback(async reportId => {
    await queryClient.prefetchQuery(['firm-report-metrics', userId, reportId], {
      queryFn: async () => {
        const { data } = await postNamedQuery('coreData', 'get-firm-wide-report-metrics', {
          reportId
        })

        return data
      }
    })
  }, [userId, queryClient])
}

export const useFirmReportData = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, keepPreviousData = false } = options
  return useQuery({
    queryKey: ['firm-report-data', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-firm-wide-report-data', query)

      return data
    },
    enabled,
    keepPreviousData
  })
}

export const useDenaliData = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, keepPreviousData = false } = options
  return useQuery({
    queryKey: ['get-denali-data', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'get-denali-data', query)

      return data
    },
    enabled,
    keepPreviousData
  })
}

export const useListFirmReportStatus = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: ['list-firm-report-status', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'list-firm-wide-report-status', query)

      return data
    },
    enabled
  })
}

export const useListBppReportTypes = (options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: ['list-bpp-report-types', userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'list-bpp-report-types', {})

      return data
    },
    enabled
  })
}

/**
 * lists bpp reports
 * @param {string} reportType
 * @param options
 * @return {{data:{reports:[{reportType: string,bppReportId: number,asOfDate: string,isProcessing: boolean,lastStartedDate: string,lastFinishedDate: string}]}}}
 */
export const useListBppReports = (reportType = undefined, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true } = options
  return useQuery({
    queryKey: ['list-bpp-reports', userId, reportType],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'list-bpp-reports', {
        reportType
      })

      return data
    },
    enabled
  })
}

export const useBppReportStatus = (bppReportId, options = {}) => {
  // const queryClient = useQueryClient()
  const { userId } = useAppContext()
  const { enabled = true, refetchInterval = null } = options
  return useQuery({
    queryKey: ['get-bpp-report-status', userId, bppReportId],
    queryFn: async () => {
      const { data } = await postNamedQuery('bobi', 'get-bpp-report-status', {
        bppReportId
      })

      return data
    },
    refetchInterval,
    refetchOnWindowFocus: !!refetchInterval,
    enabled
  })
}

export const useStartBppMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('bobi', 'start-bpp', command)

      return data
    },
    onSuccess: (data, variables) => {
      const { reportType } = variables
      queryClient.invalidateQueries({ queryKey: ['list-bpp-reports', userId, reportType], exact: true }).catch(console.error)
    }
  })
}

export const useExpireBppReportMutation = (reportType) => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('bobi', 'expire-bpp-report', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['list-bpp-reports', userId, reportType], exact: true }).catch(console.error)
    }
  })
}

export const useRestartBppReportMutation = (reportType) => {
  const queryClient = useQueryClient()
  const { userId } = useAppContext()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('bobi', 'restart-bpp-report', command)

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['list-bpp-reports', userId, reportType], exact: true }).catch(console.error)
    }
  })
}

export const useProjectedIncomeV2 = (query, options = {}) => {
  const { userId } = useAppContext()
  const { enabled = true, mapper } = options
  return useQuery({
    queryKey: ['get-projected-income-v2', userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('coreData', 'projected-income-v2', query)

      return data
    },
    enabled,
    select: mapper
  })
}
