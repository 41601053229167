import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import clsx from 'clsx'
import { useImageLoaded } from '../../hooks/useImageLoaded'

const sizes = (theme) => ({
  xs: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '10px'
  },
  sm: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '12px'
  },
  md: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  lg: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  sl: {
    width: theme.spacing(10.5),
    height: theme.spacing(10.5),
    fontSize: '30px'
  },
  xl: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '34px'
  },
  fill: {
    width: '100%',
    height: '100%'
  }
})

const useStyles = makeStyles((theme) => ({
  '@keyframes avatarIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  avatar: {
    opacity: 0,
    backgroundColor: theme.palette.gray.main,
    color: ({ abbreviation }) => abbreviation ? theme.palette.text.primary : theme.palette.gray.darker,
    animation: '$avatarIn 200ms ease-out',
    animationDelay: ({ doneLoading }) => doneLoading ? 0 : '3s',
    animationPlayState: ({ doneLoading }) => doneLoading ? 'running' : 'paused',
    animationFillMode: 'forwards'
  },
  avatarWrapper: {
    boxShadow: `0 0 1px 1px ${theme.palette.gray.main} inset`,
    borderRadius: '1000px'
  },
  ...(sizes(theme))
}))

/**
 *
 *   !!!!!!!!!!!!!!!!!                   WARNING                   !!!!!!!!!!!!!!!!!!!
 *
 *   Please never add any features to this Avatar component that may make it wait for another network request.
 *   Any use of this component MUST have the url in order
 *
 */
function FastAvatar ({ isLoading, avatarUrl, avatarType, abbreviation, size, className }) {
  const avatarText = useMemo(() => {
    return abbreviation || null
  }, [abbreviation])
  const doneLoading = useImageLoaded(avatarUrl)
  const imgProps = useMemo(() => {
    return {
      crossOrigin: (avatarType === 'public') ? 'anonymous' : undefined
    }
  }, [avatarType])
  const classes = useStyles({ abbreviation, doneLoading })

  if (isLoading) {
    return (
      <div className={clsx(className, classes.avatarWrapper)}>
        <Avatar
          className={clsx(classes.avatar, classes[size])}
          imgProps={imgProps}
        />
      </div>
    )
  }

  return (
    <div className={clsx(className, classes.avatarWrapper)}>
      <Avatar
        src={avatarUrl}
        className={clsx(classes.avatar, classes[size])}
        imgProps={imgProps}
      >
        {avatarText}
      </Avatar>
    </div>
  )
}

FastAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  avatarType: PropTypes.oneOf(['media', 'override', 'resource', 'public']),
  abbreviation: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'sl', 'xl', 'fill']),
  isLoading: PropTypes.bool,
  className: PropTypes.string
}

FastAvatar.defaultProps = {
  size: 'lg',
  isLoading: false
}

export default FastAvatar
