import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useClientBillingDetails, useClientSummary } from '../../../../../api/billing'
import { useAppContext } from '../../../../../redux/slices/appContext'
import { localStorageHelper } from '../../../../../utils/localStorageHelper'
import { useAccountSearch } from '../../../../../api/coreData'
import AddFeeScheduleDialog from './dialogs/AddFeeScheduleDialog'
import RemoveFeeScheduleDialog from './dialogs/RemoveFeeScheduleDialog'
import ReplaceFeeScheduleDialog from './dialogs/ReplaceFeeScheduleDialog'
import EditFamilyRateDialog from './dialogs/EditFamilyRateDialog'
import EditBillingStartDateDialog from './dialogs/EditBillingStartDateDialog'
import EditBillingAccountsDialog from './dialogs/EditBillingAccountsDialog'
import AddAccountOverrideDialog from './dialogs/AddAccountOverrideDialog'
import RemoveOverrideDialog from './dialogs/RemoveOverrideDialog'
import AddClassOverrideDialog from './dialogs/AddClassOverrideDialog'
import AddPositionOverrideDialog from './dialogs/AddPositionOverrideDialog'

export const ClientReviewContext = createContext({})

export function useClientReviewContext () {
  return useContext(ClientReviewContext)
}

const LOCAL_STORAGE_KEY = 'sws.cr.myClients'
const useOnlyMyClients = () => {
  const { allAccountAccess } = useAppContext()
  let defaultValue = !!allAccountAccess
  try {
    const ls = localStorageHelper.load(LOCAL_STORAGE_KEY)
    defaultValue = [null, undefined].includes(ls) ? !!allAccountAccess : ls
  } catch (err) {
    defaultValue = !!allAccountAccess
  }

  const setMyClientsDefault = useCallback((val) => {
    localStorageHelper.store(LOCAL_STORAGE_KEY, val)
  }, [])

  const [myClients, setMyClients] = useState(defaultValue)
  const _setMyClients = useCallback((val) => {
    setMyClients(val)
    setMyClientsDefault(val)
  }, [setMyClientsDefault, setMyClients])

  return [myClients, _setMyClients]
}

function ClientReviewProvider ({ children, clientsBaseQuery, accountsBaseQuery, editingSettings }) {
  const addFeeSchedule = useRef()
  const removeFeeSchedule = useRef()
  const replaceFeeSchedule = useRef()
  const editFamilyRate = useRef()
  const editBillingStartDate = useRef()
  const editBillingAccounts = useRef()
  const addAccountOverride = useRef()
  const addClassOverride = useRef()
  const addPositionOverride = useRef()
  const removeClientOverride = useRef()
  const [client, setClient] = useState(null)
  const [detail, setDetail] = useState(null)
  const [onlyMyClients, setOnlyMyClients] = useOnlyMyClients()

  const { data: clientsData, isLoading: clientsLoading, error: clientsError } = useClientSummary(clientsBaseQuery)
  const _client = useMemo(() => {
    return (clientsData || []).find(x => client?.clientId === x.clientId)
  }, [client, clientsData])

  const _accountsQuery = useMemo(() => ({
    ...(accountsBaseQuery || {}),
    filters: {
      ...(accountsBaseQuery?.baseQuery?.filters || {}),
      assignedToClientIds: client?.clientId
    },
    take: 1000
  }), [client, accountsBaseQuery])
  const { data: clientBillingData, isFetching: clientBillingDataFetching } = useClientBillingDetails(client?.clientId, { enabled: !!client?.clientId })
  const { data: accountsData, isFetching: accountsFetching } = useAccountSearch(_accountsQuery, { enabled: !!client?.clientId })

  // We project the matching value so that if the underlying data is updated, the display component can be updated with the new data
  const _detail = useMemo(() => {
    switch (detail?.type) {
      case 'account':
        return { ...detail, detail: (accountsData?.data || []).find(x => x.accountId === detail.detail.accountId), isLoading: accountsFetching }
      case 'feeSchedule':
        return { ...detail, detail: (clientBillingData?.body?.feeSchedules || []).find(x => x.feeScheduleId === detail.detail.feeScheduleId), isLoading: clientBillingDataFetching }
      case 'accountOverrides':
        return { ...detail, detail: clientBillingData?.body?.overrides?.accountOverrides || [], isLoading: clientBillingDataFetching }
      case 'classOverrides':
        return { ...detail, detail: clientBillingData?.body?.overrides?.classOverrides || [], isLoading: clientBillingDataFetching }
      case 'positionOverrides':
        return { ...detail, detail: clientBillingData?.body?.overrides?.positionOverrides || [], isLoading: clientBillingDataFetching }
      case 'assetOverrides':
        return { ...detail, detail: clientBillingData?.body?.overrides?.assetOverrides || [], isLoading: clientBillingDataFetching }
      default: return null
    }
  }, [clientBillingData, accountsData, detail, accountsFetching, clientBillingDataFetching])

  const value = useMemo(() => {
    return {
      client: _client,
      detail: _detail,
      clients: {
        data: clientsData,
        isLoading: clientsLoading,
        error: clientsError
      },
      clientBilling: {
        data: clientBillingData,
        isLoading: clientBillingDataFetching
      },
      clientAccounts: {
        data: accountsData,
        isLoading: accountsFetching
      },
      dialogs: {
        addFeeSchedule,
        removeFeeSchedule: (feeSchedule) => removeFeeSchedule.current.open(_client, feeSchedule),
        replaceFeeSchedule: (feeSchedule) => replaceFeeSchedule.current.open(_client, feeSchedule),
        editFamilyRate: (feeSchedule) => editFamilyRate.current.open(_client, feeSchedule, clientsBaseQuery),
        editBillingStartDate: (account) => editBillingStartDate.current.open(account),
        editBillingAccounts: (account, billingAccounts) => editBillingAccounts.current.open(account, billingAccounts),
        addAccountOverride: () => addAccountOverride.current.open(_client),
        addClassOverride: () => addClassOverride.current.open(_client),
        addPositionOverride: () => addPositionOverride.current.open(_client),
        removeClientOverride: (override, name) => removeClientOverride.current.open(_client, override, name)
      },
      onlyMyClients,
      setOnlyMyClients,
      selectClient: c => {
        if (c !== _client) {
          setClient(c)
          setDetail(null)
        }
      },
      setDetail,
      editingSettings
    }
  }, [
    _client, _detail, clientsBaseQuery, setClient, setDetail,
    clientsData, clientsLoading, clientsError,
    clientBillingData, clientBillingDataFetching,
    accountsData, accountsFetching,
    onlyMyClients, setOnlyMyClients,
    editingSettings
  ])

  return (
    <ClientReviewContext.Provider value={value}>
      {children}
      <AddFeeScheduleDialog ref={addFeeSchedule} />
      <RemoveFeeScheduleDialog ref={removeFeeSchedule} />
      <ReplaceFeeScheduleDialog ref={replaceFeeSchedule} />
      <EditFamilyRateDialog ref={editFamilyRate} />
      <EditBillingStartDateDialog ref={editBillingStartDate} />
      <EditBillingAccountsDialog ref={editBillingAccounts} />
      <AddAccountOverrideDialog ref={addAccountOverride} />
      <AddClassOverrideDialog ref={addClassOverride} />
      <AddPositionOverrideDialog ref={addPositionOverride} />
      <RemoveOverrideDialog ref={removeClientOverride} />
    </ClientReviewContext.Provider>
  )
}

ClientReviewProvider.propTypes = {
  children: PropTypes.node,
  clientsBaseQuery: PropTypes.object,
  accountsBaseQuery: PropTypes.object,
  editingSettings: PropTypes.object
}

export default ClientReviewProvider
