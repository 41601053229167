import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { RUN_STATUS } from '../../runBilling/helpers'
import { useOperationalTable } from '../../../../../organisms/OperationalTable'
import { useBillingRuns } from '../../../../../../api/billing'
import BillingRunDetailsTable from '../billingRunDetailsTable'
import { useColumns } from '../hooks'
import { transformColumnSortToQuery } from '../helpers'
import TableFiltersContainer from '../billingRunDetailsTable/TableFiltersContainer'
import { useCheckPolicy } from '../../../../../../hooks'
import { BILLING } from '../../../../../../policies/admin'
import { ADMIN_ROUTES } from '../../../../../../constants'
import useStyles from './styles'
import StatusFilter from './filters/StatusFilter'
import { defaultColumnConfig, presetColumnsMap } from './utils/columnConfig'

const BillingRunDetailsList = ({
  columnsConfig: _columnsConfig = defaultColumnConfig
}) => {
  const history = useHistory()
  const canViewDetails = useCheckPolicy(BILLING.viewDetails)
  if (!canViewDetails) {
    history.replace(ADMIN_ROUTES.BILLING)
  }
  const onTriggerStatusChange = async () => {
    await refetch()
  }

  const columnsConfig = useColumns({
    columnsConfig: _columnsConfig,
    presetColumns: presetColumnsMap,
    handlers: { onTriggerStatusChange }
  })
  const classes = useStyles()

  const {
    pageIndex,
    pageSize
  } = useOperationalTable(columnsConfig.defaultSort)

  const [statusFilters, setStatusFilters] = useState([RUN_STATUS.IN_REVIEW])

  const query = useMemo(
    () => ({
      take: pageSize,
      skip: pageIndex * pageSize,
      includes: { runBy: true },
      filters: {
        status: statusFilters.map((status) => ({
          op: 'eq',
          combine: 'or',
          value: status
        }))
      },
      sort: transformColumnSortToQuery(columnsConfig.defaultSort)
    }),
    [columnsConfig.defaultSort, statusFilters, pageIndex, pageSize]
  )

  const {
    data,
    isFetching,
    refetch
  } = useBillingRuns(query, {
    enabled: !!statusFilters?.length
  })

  return (

    <div className={classes.container}>
      <TableFiltersContainer>
        <div>
          <StatusFilter
            onChange={setStatusFilters}
            value={statusFilters}
          />
        </div>
      </TableFiltersContainer>

      <BillingRunDetailsTable
        loading={isFetching}
        data={data ?? []}
        columns={columnsConfig.columns}
        hiddenColumns={columnsConfig.hiddenColumns}
        defaultSort={columnsConfig.defaultSort}
      />
    </div>
  )
}

BillingRunDetailsList.propTypes = {
  columnsConfig: {
    columns: PropTypes.array,
    defaultSort: PropTypes.array
  }
}

BillingRunDetailsList.defaultProps = {}

export default BillingRunDetailsList
