/* eslint-disable react/jsx-key */
import React, { useCallback, useMemo, useRef } from 'react'
import { useColumnOrder, useExpanded, usePagination, useSortBy, useTable } from 'react-table'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import TableSkeleton from '../../../../../atoms/TableSkeleton'
import ExpandyIcon from '../../../../../organisms/PerformanceTableV2/PerformancePresentationTable/ExpandyIcon'
import { ICON_NAMES } from '../../../../../../constants'
import SydSelect from '../../../../../commonDesign/SydSelect'
import SydButton from '../../../../../commonDesign/Button'
import Icon from '../../../../../atoms/Icon'
import useStyles from './styles'

const BillingRunDetailsTable = (
  {
    columns,
    data: _data,
    defaultSort,
    searchText,
    searchFields,
    hiddenColumns,
    statusFilters,
    loading,
    updatingIds = []
  }) => {
  const tableRef = useRef(null)
  const classes = useStyles()

  const data = useMemo(() => {
    if (!searchText?.length && !statusFilters?.length) return _data
    return _data.filter(row => {
      const matchesText = !searchText?.length || searchFields.some(field => row[field].toLowerCase().includes(searchText.toLowerCase()))
      const matchesFilters = !statusFilters?.length || statusFilters.every(filter => row.statuses.includes(filter))
      return matchesText && matchesFilters
    })
  }, [searchText, statusFilters, _data, searchFields])

  const {
    getTableProps,
    headerGroups,
    page,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage
  } = useTable({
    columns,
    data,
    disableSortRemove: true,
    autoResetExpanded: false,
    initialState: {
      sortBy: defaultSort,
      pageSize: 50,
      pageIndex: 0,
      hiddenColumns: hiddenColumns ?? []
    },
    autoResetPage: false,
    autoResetGroupBy: false,
    autoResetSelectedRows: false,
    autoResetSortBy: false,
    autoResetFilters: false,
    autoResetRowState: false,
    paginateExpandedRows: false
  }, useSortBy, useExpanded, useColumnOrder, usePagination)

  const rowClickHandler = useCallback(row => () => {
    if (row.subRows?.length) {
      row.toggleRowExpanded()
    }
  }, [])

  return (
    <div ref={tableRef} className={classes.wrapper}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          <table {...getTableProps()} className={classes.table}>
            <thead className={classes.thead}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className={classes.headerRow}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ width: column.width }}
                      className={classes.headerCell}
                    >
                      <div className={classes.headerCellInternal}>
                        <span>{column.render('Header')}</span>
                        <span className={classes.sortIcon}>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <Icon name={ICON_NAMES.down} />
                              : <Icon name={ICON_NAMES.up} />
                            : ''}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={rowClickHandler(row)}
                    className={clsx(
                      classes.bodyRow, {
                        __child: (row.depth > 0),
                        __expanded: !!row.isExpanded,
                        __expandable: !!row.subRows?.length,
                        __disabled: row.original?.disabled,
                        __updating: updatingIds?.includes(row?.original?.id)
                      }, `__depth_${row.depth}`)}
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={classes.bodyCell}
                      >
                        {index === 0 && !!row.subRows?.length ? (
                          <div className={classes.expandContainer}>
                            {updatingIds?.includes(row?.original?.id)
                              ? <CircularProgress size={16} /> : (
                                <ExpandyIcon
                                  className={classes.expandHandle} enabled={!!row.subRows?.length}
                                  expanded={row.isExpanded}
                                />
                              )}
                            {cell.render('Cell')}
                          </div>
                        ) : cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className={classes.footerContainer}>
            <div className={classes.paginationContainer}>
              <div className={classes.pageSelectContainer}>
                <span>Showing</span>
                <div style={{ width: '80px' }}>
                  <SydSelect
                    size='xs'
                    value={page.length}
                    options={[10, 50, 100, 1000]}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  />
                </div>
                <span>of</span>
                <span className={classes.totalCount}>{data.length}</span>
                <span>Billing Run Records</span>
              </div>
              <div className='pageIncrementContainer'>
                <SydButton variant='ghost' onClick={() => previousPage()} disabled={!canPreviousPage}>
                  <Icon name='chevronLeft' margin='0 6px 0 0' customSize={18} />
                  Prev
                </SydButton>
                <SydButton variant='ghost' onClick={() => nextPage()} disabled={!canNextPage}>
                  Next
                  <Icon name='chevronRight' margin='0 0 0 6px' customSize={18} />
                </SydButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

BillingRunDetailsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSort: PropTypes.array,
  searchText: PropTypes.string,
  searchFields: PropTypes.array,
  hiddenColumns: PropTypes.array,
  statusFilters: PropTypes.array,
  loading: PropTypes.bool,
  updatingIds: PropTypes.array
}

export default BillingRunDetailsTable
