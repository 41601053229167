import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { ADMIN_ROUTES } from '../../../../../../../constants'
import SydButton from '../../../../../../commonDesign/Button'
import { RUN_STATUS, RUN_STATUS_LABELS } from '../../../runBilling/helpers'
import { useCheckPolicy } from '../../../../../../../hooks'
import { BILLING } from '../../../../../../../policies/admin'
import { updateBillingRun } from '../../../../../../../service'
import Alert from '../../../../../../atoms/Alert'

const CellActions = ({ row: { original }, onTriggerStatusChange }) => {
  const history = useHistory()
  const userCanEdit = useCheckPolicy(BILLING.viewDetails)
  const [error, setError] = useState(null)

  const billingRunIsEditable = useMemo(() =>
    userCanEdit && original.status === RUN_STATUS.IN_REVIEW
  , [userCanEdit, original.status])

  const onChangeRunStatus = async (status) => {
    try {
      await updateBillingRun(original.billingRunId, { status })
      onTriggerStatusChange()
    } catch (err) {
      setError('Failed to update billing run status')
    }
  }

  return (
    <>
      {error?.length && <Alert severity='warning'>{error}</Alert>}
      <Box display='flex' flexWrap='wrap' justifyContent='space-between' gridGap={8}>
        <SydButton
          onClick={() => history.push(`${ADMIN_ROUTES.BILLING_RUN_DETAILS}/${original.billingRunId}`)}
          variant='secondary'
          size='sm'
        >
          {billingRunIsEditable ? 'Edit Details' : 'View Details'}
        </SydButton>
        {original.status === RUN_STATUS.IN_REVIEW && (
          <SydButton
            onClick={() => onChangeRunStatus(RUN_STATUS.MARK_AS_FINAL)}
            variant='primary'
            size='sm'
          >
            {RUN_STATUS_LABELS[RUN_STATUS.MARK_AS_FINAL]}
          </SydButton>
        )}
      </Box>
    </>
  )
}

CellActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object
  }),
  onTriggerStatusChange: PropTypes.func
}

export default CellActions
